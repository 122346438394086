const headers = [
  {
    text: 'Nombre Actividad',
    value: 'name',
    sortable: false
  },
  {
    text: 'Fecha',
    value: 'date',
    sortable: false
  },
  {
    text: 'Horario',
    value: 'schedule',
    sortable: false
  },
  {
    text: 'Activo',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '15%'
  }
]
export default headers
