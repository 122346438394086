<template>
  <fair-layout v-if="fair" :fair-id="fair.id" title="Configuración Evento Académico">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row mt-5">
          <div class="col-3 row">
            <div class="col py-0">
              <label class="label-chooser">Banner Interno</label>
              <file-chooser
                ref="academicActivityFileChooser"
                @input="onLoadAcademicActivityImageUrl"
                :options="{
                height: '110px',
                description: 'Haz click para cargar la imagen',
                regex: /\.(jpg|jpeg|png|gif)$/,
                error: 'Solo se permiten imagenes',
                fileSize: 2,
              }"
              ></file-chooser>
            </div>
            <v-tooltip bottom class="col-auto">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onDeleteImage"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>Eliminar Imagen</span>
            </v-tooltip>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-0 pb-0 d-flex align-center justify-space-between">
            <p>
              Los recursos de imágenes para las secciones deben cargarse en formato PNG o JPG,
              de dimensiones (1280px por 737px) y tamaño no superior a 100kbs.
            </p>
            <v-btn
              color="secondary"
              class="text--primary text-none elevation-0"
              @click="openAdminExpositorModal()"
              dark>
              Nuevo
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-text-field
              v-model="params.filterName"
              label="Nombres Actividad"
              v-on:keyup.enter="search"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-end">
            <v-btn
              color="primary"
              @click="search"
              class="white--text text-none elevation-0 mr-2">
              <v-icon
                left
                dark>
                fa-search
              </v-icon>
              Buscar
            </v-btn>
            <v-btn
              color="default"
              @click="clean"
              class="text-none elevation-0">
              <v-icon
                left
                dark>
                fa-broom
              </v-icon>
              Limpiar
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table ref="academicActivities" :url="url" :params="params" :headers="headers" :mobile="true">
              <template v-slot:item.isActive="{ item }">
                <v-switch v-model="item.isActive" hide-details dense class="ma-0" @click="onChangeStatusAcademicActivity(item)"></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openAdminExpositorModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Actividad</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="onDeleteActivity(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Actividad</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <academic-activity-modal ref="academicActivityModal" :fairId="fair.id" @onClose="onClose"/>
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas ${(Boolean(selectedActivity.isActive) ? 'activar' : 'desactivar')}  la actividad ${selectedActivity.name}?`"
          @onClose="closeChangeStatusActivityModal()"
          @onConfirm="changeStatusAcademicActivity()"
          :is-active="isConfirmChangeStatusAcademicActive"
        />
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas eliminar la actividad ${selectedActivity.name}?`"
          @onClose="closeDeleteActivityModal()"
          @onConfirm="deleteAcademicActivity()"
          :is-active="isConfirmDeleteActivity"
        />
        <confirm-dialog
          :title="'Confirmación'"
          description="¿Deseas eliminar la imagen del evento académico"
          @onClose="isConfirmDeleteImage = false"
          @onConfirm="deleteImage()"
          :is-active="isConfirmDeleteImage"
        />
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import {
  ACADEMIC_ACTIVITIES_URL,
  APP_MODULE_SAVE_INTERNAL_IMAGE
} from '@/constants/ServicesMobileConstants'
import headers from './data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import AcademicActivityModal from './components/AcademicActivityModal'
import Banner from '@/components/banner/Banner'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import FileChooser from '@/components/file-chooser/FileChooser'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    ConfirmDialog,
    DataTable,
    FileChooser,
    AcademicActivityModal
  },
  data () {
    return {
      headers,
      url: ACADEMIC_ACTIVITIES_URL,
      params: {
        filterName: '',
        fairId: this.$route.params.fairId
      },
      fair: null,
      appModule: null,
      selectedActivity: {},
      isConfirmChangeStatusAcademicActive: false,
      isConfirmDeleteActivity: false,
      isConfirmDeleteImage: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    clean () {
      this.params.filterName = null
      this.search()
    },
    onClose () {
      this.$refs.academicActivities.getDataFromApi()
    },
    openAdminExpositorModal (item) {
      this.$refs.academicActivityModal.open(item)
    },
    search () {
      this.$refs.academicActivities.getDataFromApi()
    },
    onDeleteActivity (item) {
      this.selectedActivity = item
      this.isConfirmDeleteActivity = true
    },
    onChangeStatusAcademicActivity (item) {
      this.selectedActivity = item
      this.isConfirmChangeStatusAcademicActive = true
    },
    changeStatusAcademicActivity () {
      this.isConfirmChangeStatusAcademicActive = false
      this.putMobile(ACADEMIC_ACTIVITIES_URL + '/change-status/' + this.selectedActivity.id, this.selectedActivity)
        .then((res) => {
          this.showSuccess(res.data.success)
          this.$refs.academicActivities.getDataFromApi()
        })
    },
    closeChangeStatusActivityModal () {
      this.isConfirmChangeStatusAcademicActive = false
      this.selectedActivity.isActive = !this.selectedActivity.isActive
    },
    onLoadAcademicActivityImageUrl (file) {
      if (file) {
        const formData = new FormData()
        formData.append('fairId', this.fair.id)
        formData.append('appModuleImage', file)
        this.postMobile(ACADEMIC_ACTIVITIES_URL + '/image', formData)
          .then((res) => {
            this.showSuccess(res.data.success)
          })
          .catch((err) => this.showError(err.response.data.error))
      }
    },
    deleteAcademicActivity () {
      this.isConfirmDeleteActivity = false
      this.deleteMobile(ACADEMIC_ACTIVITIES_URL, this.selectedActivity.id)
        .then((res) => {
          this.showSuccess(res.data.success)
          this.$refs.academicActivities.getDataFromApi()
        })
    },
    closeDeleteActivityModal () {
      this.isConfirmDeleteActivity = false
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
      await this.getAppModule()
    },
    async getAppModule () {
      const response = await this.getMobile(ACADEMIC_ACTIVITIES_URL + '/' + this.$route.params.fairId)
      this.appModule = response.data
      this.$nextTick(() => {
        if (this.appModule.internalImageUrl) {
          this.$refs.academicActivityFileChooser.setImage(this.appModule.internalImageUrl, true)
        }
      })
    },
    onDeleteImage () {
      this.isConfirmDeleteImage = true
    },
    deleteImage () {
      this.deleteMobile(APP_MODULE_SAVE_INTERNAL_IMAGE, this.appModule.id)
        .then((res) => {
          this.$refs.academicActivityFileChooser.setImage(null)
          this.isConfirmDeleteImage = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    crudServiceMobileMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'AcademicActivities'
}
</script>
<style scoped>
  .label-chooser {
    font-weight: bold !important;
    font-size: 13px !important;
  }

  .image-input {
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
  }
</style>
